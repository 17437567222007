import React from 'react';
import { StyleSheet, View, SafeAreaView, ScrollView, Platform } from 'react-native';
import AlertPopUpModal from '../components/AlertPopUpModal';
import orderService from '../services/orders';
import OrderCard from '../components/OrderCard';
import I18n from '../utils/i18n';
import config from '../config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import KeepAwake from 'react-native-keep-awake';
import userService from './../services/user';
import CustomChip from '../components/CustomChip';

class OrdersDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      bars: [],
      inQueueOrders: [],
      processingOrders: [],
      readyOrders: [],
      totalOrdersCount: 0,
      loading: false,
      isModalVisible: false,
      modal: {
        icon: '',
        title: '',
        text: '',
      },
      status: null,
      role: null,
    }
  }

  async componentDidMount(show_loading = true) {

    let current_role = await AsyncStorage.getItem(config.roleKey);
    this.setState({ loading: true, isModalVisible: show_loading && true, modal: this.loading_modal_props, role: current_role });

    let isAlwaysOnDisplay = await AsyncStorage.getItem('isAlwaysOnDisplay');
    if (isAlwaysOnDisplay) {
      isAlwaysOnDisplay = JSON.parse(isAlwaysOnDisplay);
      if (isAlwaysOnDisplay) {
        if (Platform.OS != 'web') {
          KeepAwake.activate();
        }

        this.timer = setInterval(() => {
          this.getOrders(false, (Platform.OS == 'ios' ? false : true));
        }, 1000 * config.orderRefreshInterval);

      } else {
        if (Platform.OS != 'web') {
          KeepAwake.deactivate();
        }
      }
    } else {
      this.timer = setInterval(() => {
        this.getOrders(false, (Platform.OS == 'ios' ? false : true));
      }, 1000 * config.orderRefreshIntervalLong);
    }

    this.setState({ loading: false, isModalVisible: show_loading && true });

    await this.getOrders(show_loading);
    
    let profileRes = await userService.get_profile();
    if (profileRes.error) {
      this.props?.navigation?.reset({
        index: 0,
        routes: [{ name: 'Login', params: { redirectTo: 'Orders' } }],
      });
      return;
    }

  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  async getOrders(show_loading, show_small_loading = true) {

    await this.setState({
      loading: show_small_loading, isModalVisible: show_loading && true, modal: this.loading_modal_props
    });

    let inQueueOrdersPromise = orderService.get_orders(config.orderStatus.CONFIRMED.key);
    let processingOrdersPromise = orderService.get_orders(config.orderStatus.PROCESSING.key);
    let readyOrdersPromise = orderService.get_orders(config.orderStatus.READY.key);
    
    let ordersRes = await Promise.all([inQueueOrdersPromise, processingOrdersPromise, readyOrdersPromise]);
    this.setState({
      inQueueOrders: ordersRes[0]?.error ? [] : ordersRes[0].orders,
      processingOrders: ordersRes[1]?.error ? [] : ordersRes[1].orders,
      readyOrders: ordersRes[2]?.error ? [] : ordersRes[2].orders,
      isModalVisible: false
    });
  }

  OrderCardView = (order, index) => {
    return (
      <OrderCard
        key={index}
        id={order._id}
        orderNumber={order.shortOrderNumber}
        beeperNumber={((this.state.role == config.roles.CASHIER.key) || (this.state.role == config.roles.SERVER.key)) ? order.beeperNo : null}
        total={order.total}
        currencyCode={order.currencyCode}
        paymentType={order?.payrexxPayment?.brand ? order?.payrexxPayment?.brand : order?.paymentType}
        status={order.status}
        payrexxStatus={order.payrexxStatus}
        secondary_title={((this.state.role == config.roles.CASHIER.key) || (this.state.role == config.roles.SERVER.key)) ? (order?.groupName) : (order?.barName + ' > ' + order?.groupName)}
        user={(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? order?.user : null}
        description={order?.createdAt ? moment(order.createdAt).format(I18n.t('OPTIONS.timestampFormat')) : ''}
        address={order?.address}
      />
    )
  }

  render() {

    return (
      <SafeAreaView style={styles.container}>

        <ScrollView horizontal={true}
          contentContainerStyle={{justifyContent: 'space-evenly', minWidth: '100%'}}
        >


          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{flex: 1, minWidth: 350, margin: 5}}>
              <CustomChip text={config.orderStatus.CONFIRMED.name} /> 
              <ScrollView>
                {this.state.inQueueOrders.map((order, index) => {
                  return this.OrderCardView(order, index);
                })}
              </ScrollView>
            </View>
            <View style={{flex: 1, minWidth: 350, margin: 5}}>
              <CustomChip text={config.orderStatus.PROCESSING.name} backgroundColor={"orange"} color={"white"} borderColor={"orange"} /> 
              <ScrollView>
                {this.state.processingOrders.map((order, index) => {
                  return this.OrderCardView(order, index);
                })}
              </ScrollView>
            </View>
            <View style={{flex: 1, minWidth: 350, margin: 5}}>
              <CustomChip text={config.orderStatus.READY.name} backgroundColor={"white"} color={"green"} borderColor={"green"} /> 
              <ScrollView>
                {this.state.readyOrders.map((order, index) => {
                  return this.OrderCardView(order, index);
                })}
              </ScrollView>
            </View>
          </View>
          
          <AlertPopUpModal
            isVisible={this.state.isModalVisible}
            icon={this.state.modal.icon}
            title={this.state.modal.title}
            text={this.state.modal.text}
            okCallback={this.state.modal.okCallback}
          ></AlertPopUpModal>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  }
});

export default OrdersDisplay;
