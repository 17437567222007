import React from 'react';
import {StyleSheet, View, Text, SafeAreaView, ScrollView, StatusBar, RefreshControl, Image, Platform, TouchableOpacity} from 'react-native';
import AlertPopUpModal from '../components/AlertPopUpModal';
import BottomNavigationBar from '../components/BottomNavigationBar';
import orderService from '../services/orders';
import payrexxService from '../services/payrexx';
import OrderCard from '../components/OrderCard';
import I18n from '../utils/i18n';
import config from '../config';
import TicketIcon from './../assets/icons/ticket.svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import KeepAwake from 'react-native-keep-awake';
import RefreshButton from '../components/RefreshButton';
import commonStyles from './../styles/common';
import { isWideMode } from '../utils/common';
import OrderDetails from './OrderDetails';
import LoadMoreButton from '../components/LoadMoreButton';
import userService from './../services/user';
import NotificationCheck from '../components/NotificationError';
import { getTwintDirectPaymentStatus } from '../services/twintDirect';

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.orderGroupId = this.props?.route?.params?.orderGroupId;
    // Twint payment
    this.payrexxId = this.props?.route?.params?.payrexxId;
    this.payrexxReferenceId = this.props?.route?.params?.payrexxReferenceId;
    // Twint Direct payment
    this.merchantTranId = this.props?.route?.params?.merchantTranId;
    

    this.timer = null;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      bars: [],
      orders: [],
      totalOrdersCount: 0,
      loading: false,
      isModalVisible: false,
      limit: config.orderLimit,
      skip: 0,
      modal: {
        icon: '',
        title: '',
        text: '',
      },
      status: null,
      role: null,
      selectedOrderId: null,
      quickOrderFilter: false,
      activeOrdersTab: 'inprogress',
    }
  }

  async componentDidMount(show_loading = true) {

    let current_role = await AsyncStorage.getItem(config.roleKey);
    this.setState({ loading: true, isModalVisible: show_loading && true, modal: this.loading_modal_props, role: current_role });

    this.orderGroupId = this.props?.route?.params?.orderGroupId;
    // Twint payment
    this.payrexxId = this.props?.route?.params?.payrexxId;
    this.payrexxReferenceId = this.props?.route?.params?.payrexxReferenceId;
    // Twint Direct payment
    this.merchantTranId = this.props?.route?.params?.merchantTranId;

    let isAlwaysOnDisplay = await AsyncStorage.getItem('isAlwaysOnDisplay');
    if (isAlwaysOnDisplay) {
      isAlwaysOnDisplay = JSON.parse(isAlwaysOnDisplay);
      if (isAlwaysOnDisplay) {
        if (Platform.OS != 'web') {
          KeepAwake.activate();
        }

        this.timer = setInterval(() => {
          this.getOrders(false, (Platform.OS == 'ios' ? false : true), this.state.skip + this.state.limit);
        }, 1000 * config.orderRefreshInterval);

      } else {
        if (Platform.OS != 'web') {
          KeepAwake.deactivate();
        }
      }
    } else {
      this.timer = setInterval(() => {
        this.getOrders(false, (Platform.OS == 'ios' ? false : true), this.state.skip + this.state.limit);
      }, 1000 * config.orderRefreshIntervalLong);
    }

    this.setState({ loading: false, isModalVisible: show_loading && true });
    
    if (!this.payrexxId || !this.merchantTranId) {
      await this.getOrders(show_loading);
    } else {
      this.setState({ loading: true, isModalVisible: true });

      let payment_confirmed = null;
      
      if (this.payrexxId) {
        let payrexxRes = await payrexxService.get_gateway(this.payrexxId);

        // remove payrexxId & payrexxReferenceId from URL params
        this.props.navigation.setParams({
          payrexxId: undefined,
          payrexxReferenceId: undefined
        });
        this.payrexxId = null;
        this.payrexxReferenceId = null;

        if (payrexxRes.error) {
          this.setState({
            modal: {
              ...this.default_modal_props,
              icon: 'error',
              title: I18n.t('error'),
              text: I18n.t(payrexxRes.error_code),
              okCallback: async () => {
                await this.getOrders(show_loading);
                this.setState({ isModalVisible: false });
              }
            }
          });
          return
        }

        payment_confirmed = ((payrexxRes.gateway.status == "confirmed") || (payrexxRes.gateway.status == "completed"));

      } else if (this.merchantTranId) {
        let twintDirectRes = await getTwintDirectPaymentStatus(this.merchantTranId);

        // remove merchantTranId from URL params
        this.props.navigation.setParams({
          merchantTranId: undefined,
          token: undefined
        });
        this.merchantTranId = null;

        if (twintDirectRes.error) {
          this.setState({
            modal: {
              ...this.default_modal_props,
              icon: 'error',
              title: I18n.t('error'),
              text: I18n.t(twintDirectRes.error_code),
              okCallback: async () => {
                await this.getOrders(show_loading);
                this.setState({ isModalVisible: false });
              }
            }
          });
          return
        }

        payment_confirmed = ((twintDirectRes.orderReason == "ORDER_OK") || (twintDirectRes.orderStatus == "SUCCESS"));
      }
      
      if (payment_confirmed) {
        this.setState({
          modal: {
            ...this.default_modal_props,
            icon: 'success',
            title: I18n.t('success'),
            text: I18n.t('payment_successful'),
            okCallback: async () => {
              await this.getOrders(show_loading);
              this.setState({
                loading: false,
                isModalVisible: false
              });
            }
          }
        });
      } else {
        this.setState({
          modal: {
            ...this.default_modal_props,
            icon: 'error',
            title: I18n.t('error'),
            text: I18n.t('payment_failed'),
            okCallback: async () => {
              await this.getOrders(show_loading);
              this.setState({
                loading: false,
                isModalVisible: false
              });
            }
          }
        });
      }
    }

    let profileRes = await userService.get_profile();
    if (profileRes.error) {
      try {
        // await AsyncStorage.clear()
      } catch (e) {
        // clear error
      }
      this.props?.navigation?.reset({
        index: 0,
        routes: [{ name: 'Login', params: { redirectTo: 'Orders' } }],
      });
      return;
    }

  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  async getOrders(show_loading, show_small_loading=true, limit=config.orderLimit, skip=0, push=false) {

    if (push && this.state.orders.length >= this.state.totalOrdersCount) {
      return
    }

    await this.setState({
      loading: show_small_loading, isModalVisible: show_loading && true, modal: this.loading_modal_props,
      limit: limit, skip: skip
    });

    let ordersRes;
    if (this.state.activeOrdersTab == 'inprogress') {
      ordersRes = await orderService.get_orders(this.state.status, limit, skip, this.state.quickOrderFilter);
    } else if (this.state.activeOrdersTab == 'completed') {
      ordersRes = await orderService.get_completed_orders(this.state.status, limit, skip);
    } else if (this.state.activeOrdersTab == 'cancelled') {
      ordersRes = await orderService.get_cancelled_orders(this.state.status, limit, skip);
    } else {
      ordersRes = await orderService.get_orders(null, limit, skip);
    }
    
    if(ordersRes.error) {
      this.setState({
        loading: false,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: ordersRes.error
        }
      });
      return
    }

    let orders = ordersRes.orders;
    let totalOrdersCount = ordersRes.count ? ordersRes.count : 0;
    if (push) {
      orders = [...this.state.orders, ...orders];
    }

    let selectedOrderId = this.state.selectedOrderId;
    // if selectedOrder is not in orders, reset selectedOrderId
    if (this.state.selectedOrderId && !orders.find(order => order._id == this.state.selectedOrderId)) {
      selectedOrderId = null
    }

    this.setState({
      orders: orders,
      totalOrdersCount: totalOrdersCount,
      isModalVisible: show_loading && false,
      loading: false,
      selectedOrderId: selectedOrderId
    });
  }

  async filterResults(activeStatuses) {
    await this.setState({ status: activeStatuses.join(",") });
    await this.getOrders(false);
  }

  render() {

    return (
      <SafeAreaView style={styles.container}>

        <View>
          <NotificationCheck />
        </View>

        <View style={{width: '100%', height: '100%', flex: 1, flexDirection: 'row'}}>
        <View style={isWideMode() ? commonStyles.leftSideView : commonStyles.centeredView}>
        <ScrollView style={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={this.state.loading}
              onRefresh={() => {
                this.getOrders(false);
              }}
            />
          }
          paginationEnabled={true}
          onEndReachedThreshold={0.5}
          onMomentumScrollEnd={(event) => {
            const isAtAnd = (parseInt(event.nativeEvent.contentOffset.y) + parseInt(event.nativeEvent.layoutMeasurement.height)) === parseInt(event.nativeEvent.contentSize.height);
            if (isAtAnd) {
              this.getOrders(false, true, this.state.limit, this.state.skip + this.state.limit, true);
            }
          }}
          onScrollEndDrag={(event) => {
            const isAtAnd = (parseInt(event.nativeEvent.contentOffset.y) + parseInt(event.nativeEvent.layoutMeasurement.height)) === parseInt(event.nativeEvent.contentSize.height);
            if (isAtAnd) {
              this.getOrders(false, true, this.state.limit, this.state.skip + this.state.limit, true);
            }
          }}
        >

          {(Platform.OS == 'web') ? (
            <RefreshButton
              onPress={() => {
                this.getOrders(false);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          {(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? (
            <View style={{flexDirection: 'row', padding: 10}}>
              <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                {this.state.activeOrdersTab == "inprogress" ? (
                  <TouchableOpacity
                    style={(this.state.quickOrderFilter) ? {backgroundColor: 'green', padding: 10, borderRadius: 5, marginRight: 10} : {backgroundColor: 'white', padding: 10, borderRadius: 5, marginRight: 10, borderColor: 'green', borderWidth: 2}}
                    onPress={async () => {
                      let quickOrderFilter = !this.state.quickOrderFilter;
                      await this.setState({
                        quickOrderFilter: quickOrderFilter
                      })
                      this.getOrders(true)
                    }}
                    disabled={this.state.activeOrdersTab != "inprogress"}
                  >
                    <Text style={(this.state.quickOrderFilter) ? {color: 'white', fontSize: 20} : {color: 'green', fontSize: 20}}>{I18n.t('quick_order')}</Text>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={(this.state.quickOrderFilter) ? {backgroundColor: 'gray', padding: 10, borderRadius: 5, marginRight: 10} : {backgroundColor: 'white', padding: 10, borderRadius: 5, marginRight: 10, borderColor: 'gray', borderWidth: 2}}
                    disabled={true}
                  >
                    <Text style={(this.state.quickOrderFilter) ? {color: 'white', fontSize: 20} : {color: 'gray', fontSize: 20}}>{I18n.t('quick_order')}</Text>
                  </TouchableOpacity>
                )}
                <TouchableOpacity
                  style={(this.state.activeOrdersTab == "inprogress") ? {backgroundColor: config.primaryColor, padding: 10, borderRadius: 5, marginRight: 10} : {backgroundColor: 'white', padding: 10, borderRadius: 5, marginRight: 10, borderColor: config.primaryColor, borderWidth: 2}}
                  onPress={async () => {
                    await this.setState({
                      activeOrdersTab: "inprogress"
                    })
                    this.getOrders(true)
                  }}
                >
                  <Text style={(this.state.activeOrdersTab == "inprogress") ? {color: 'white', fontSize: 20} : {color: config.primaryColor, fontSize: 20}}>{I18n.t('in_progress')}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={(this.state.activeOrdersTab == "completed") ? {backgroundColor: config.primaryColor, padding: 10, borderRadius: 5, marginRight: 10} : {backgroundColor: 'white', padding: 10, borderRadius: 5, marginRight: 10, borderColor: config.primaryColor, borderWidth: 2}}
                  onPress={async () => {
                    await this.setState({
                      activeOrdersTab: "completed"
                    })
                    this.getOrders(true)
                  }}
                >
                  <Text style={(this.state.activeOrdersTab == "completed") ? {color: 'white', fontSize: 20} : {color: config.primaryColor, fontSize: 20}}>{I18n.t('completed')}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={(this.state.activeOrdersTab == "cancelled") ? {backgroundColor: config.primaryColor, padding: 10, borderRadius: 5, marginRight: 10} : {backgroundColor: 'white', padding: 10, borderRadius: 5, marginRight: 10, borderColor: config.primaryColor, borderWidth: 2}}
                  onPress={async () => {
                    this.setState({
                      activeOrdersTab: "cancelled"
                    })
                    this.getOrders(true)
                  }}
                >
                  <Text style={(this.state.activeOrdersTab == "cancelled") ? {color: 'white', fontSize: 20} : {color: config.primaryColor, fontSize: 20}}>{I18n.t('cancelled')}</Text>
                </TouchableOpacity>
              </ScrollView>
            </View>
          ) : null}

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <View style={{ width: '100%' }}>

              {this.state.orders.length == 0 ? (
                <View style={{ alignItems: 'center', margin: 20 }}>
                  {(Platform.OS == "web") ? (
                    <Image source={require('./../assets/icons/ticket.svg')} style={{ width: 100, height: 100 }} />
                  ) : (
                    <TicketIcon width={100} height={100} />
                  )}
                  <Text style={{fontSize: 30, fontWeight: '600', marginTop: 30, color: config.primaryColor}}>
                    {I18n.t('no_order_tickets_yet')}
                  </Text>
                </View>
              ) : null}
              
              {this.state.orders.map((order, index) => {
                return (
                  <OrderCard
                    key={index}
                    id={order._id}
                    highlighted={this.state.selectedOrderId == order._id}
                    orderNumber={order.shortOrderNumber}
                    beeperNumber={((this.state.role == config.roles.CASHIER.key) || (this.state.role == config.roles.SERVER.key)) ? order.beeperNo : null}
                    total={order.total}
                    currencyCode={order.currencyCode}
                    paymentType={order?.payrexxPayment?.brand ? order?.payrexxPayment?.brand : order?.paymentType}
                    status={order.status}
                    payrexxStatus={order.payrexxStatus}
                    secondary_title={((this.state.role == config.roles.CASHIER.key) || (this.state.role == config.roles.SERVER.key)) ? (order?.groupName) : (order?.barName + ' > ' + order?.groupName)}
                    user={(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? order?.user : null}
                    description={order?.createdAt ? moment(order.createdAt).format(I18n.t('OPTIONS.timestampFormat')) : ''}
                    address={order?.address}
                    onClick={async () => {
                      if (isWideMode()) {
                        await this.setState({
                          selectedOrderId: null
                        })
                        await this.setState({
                          selectedOrderId: order._id
                        })
                      } else {
                        this.props.navigation.navigate('OrderDetails', { orderId: order._id });
                      }
                    }}
                  />
                )
              })}
              
            </View>
          </View>
          
          {(Platform.OS == 'web' && this.state.totalOrdersCount && (this.state.orders.length < this.state.totalOrdersCount)) ? (
            <LoadMoreButton
              onPress={() => {
                this.getOrders(false, true, this.state.limit, this.state.skip + this.state.limit, true);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          <AlertPopUpModal
            isVisible={this.state.isModalVisible}
            icon={this.state.modal.icon}
            title={this.state.modal.title}
            text={this.state.modal.text}
            okCallback={this.state.modal.okCallback}
          ></AlertPopUpModal>

        </ScrollView>
        <View>
          {/* {(this.state?.role == config.roles.CASHIER.key || this.state?.role == config.roles.SERVER.key) ? (
            <OrderStatusSelection onStatusChange={(activeStatuses) => this.filterResults(activeStatuses)} />
          ) : null} */}
          <BottomNavigationBar {...this.props} activeTab="Orders" />
        </View>
        </View>
        
        
        {isWideMode() ? (
        <View style={{...commonStyles.rightSideView}}>
        <View style={{width: '100%', height: '100%'}}>
            {this.state.selectedOrderId ? (
              <OrderDetails
                orderId={this.state.selectedOrderId}
                showFooter={false}
                onOrderStatusChange={() => {
                  this.getOrders(false, (Platform.OS == 'ios' ? false : true), this.state.skip + this.state.limit);
                }}
              />
            ) : (
              <View style={{ alignItems: 'center', margin: 20 }}>
                {(Platform.OS == "web") ? (
                  <Image source={require('./../assets/icons/ticket.svg')} style={{ width: 100, height: 100 }} />
                ) : (
                  <TicketIcon width={100} height={100} />
                )}
                <Text style={{fontSize: 30, fontWeight: '600', marginTop: 30, color: config.primaryColor}}>
                  {I18n.t('no_order_selected')}
                </Text>
              </View>
            )}
        </View>
        </View>
        ) : null}
        
        
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
});

export default Orders;
