import React from 'react';
import {StyleSheet, View, Text, SafeAreaView, ScrollView, TouchableOpacity, RefreshControl, Image, Platform} from 'react-native';
import BottomNavigationBar from '../components/BottomNavigationBar';
import ConsumptionRowHorizontal from '../components/ConsumptionRowHorizontal';
import config from '../config';
import cartService from '../services/cart';
import AlertPopUpModal from '../components/AlertPopUpModal';
import I18n from '../utils/i18n';
import DishesIcon from './../assets/icons/dishes.svg';
import { convertMessageCodeToMessage, formatPriceWithCurrencyCode, generateAssetLink } from '../utils/common';
import RefreshButton from '../components/RefreshButton';
import RightChevronIcon from './../assets/icons/right-chevron.svg';
import MinusIcon from './../assets/icons/minus.svg';
import commonStyles from './../styles/common';
import { isWideMode } from '../utils/common';
import AsyncStorage from '@react-native-async-storage/async-storage';
import barsService from '../services/bars';
import moment from 'moment';
import authService from '../services/auth';

class Consumptions extends React.Component {
  constructor(props) {
    super(props);
    this.barId = this.props?.route?.params?.barId || this.props?.barId;
    this.tableNumber = this.props?.route?.params?.tableNumber || this.props?.tableNumber;
    this.activeTab =  this.props?.route?.params?.activeTab || this.props?.activeTab;

    this.loading_modal_props = {
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      role: config.roles.USER.key,
      consumptions: [],
      activeFilterTabs: [],
      cart: [],
      loading: false,
      isModalVisible: false,
      modal: {
        icon: '',
        title: '',
        text: '',
      },
      bar: null,
      groups: [],
      group: null,
      category: null,
      requireLogin: false,
      autoRedirected: this?.props?.route?.params?.autoRedirected || this.props?.autoRedirected || false
    }
  }

  async setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async componentDidMount( show_loading = true ) {
    const role = await AsyncStorage.getItem('role');
    this.setState({
      role,
      loading: true,
      isModalVisible: show_loading && true,
      modal: this.loading_modal_props,
      activeFilterTabs: []
    });

    if (role == config.roles.CASHIER.key || role == config.roles.SERVER.key) {
      this.barId = (JSON.parse(await AsyncStorage.getItem(config.barsKey)))[0];
    }
    
    let localCart = await cartService.get_local_cart();

    if (!localCart || localCart.error) {
      await cartService.delete_local_cart();
      localCart = [];
    } else if (localCart.length && localCart[0].bar !== this.barId) {
      await cartService.delete_local_cart();
      localCart = [];
    }
    
    await this.getConsumptions();

    let groups = this.state.groups;
    groups.forEach((group) => {
      group.categories.forEach((category) => {
        category.consumptions.forEach((consumption) => {
          let localCartItemIndex = localCart.findIndex((item) => item.consumption === consumption._id);
          if (localCartItemIndex !== -1) {
            consumption.quantity = localCart[localCartItemIndex].quantity;
          }
        });
      });
    });

    this.setState({
      cart: localCart,
      loading: false,
      isModalVisible: false
    });

  }

  async saveCart() {
    cartService.put_local_cart(this.state.cart);
    this.props.navigation.navigate('Cart', { tableNumber: this.tableNumber });
  }

  // componentWillUnmount() {
  //   this._unsubscribeBeforeRemove();
  // }

  async getConsumptions(query={}, options={}) {
    
    let default_query = {};
    let default_options = {show_loading: true, show_small_loading: true, push: false};
    
    query = {...default_query, ...query};
    options = {...default_options, ...options};

    await this.setState({
      loading: options.show_small_loading, isModalVisible: options.show_loading && true, modal: this.loading_modal_props
    });

    let bar_id = this.props?.route?.params?.barId || this.props?.barId || this.barId;
    let barRes;
    if (this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) {
      bar_id = (JSON.parse(await AsyncStorage.getItem(config.barsKey)))[0];
    }
    
    if (!bar_id) {
      this.setState({
        loading: false,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t('bar_id_is_required')
        }
      });
      return
    }

    barRes = await barsService.get_bar(bar_id);
    if(barRes.error) {
      this.setState({
        loading: false,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: barRes.error_code ? convertMessageCodeToMessage(barRes.error_code) : barRes.error
        }
      });
      return
    }

    let consumptions = [];
    barRes.bar?.groups.forEach(group => {
      group.categories?.forEach(category => {
        consumptions = consumptions.concat(category.consumptions);
      });
    });

    await this.setStateAsync({
      bar: barRes.bar,
      groups: barRes?.bar?.groups ? barRes.bar.groups : [],
      consumptions: consumptions,
      isModalVisible: options.show_loading && false,
      loading: false,
      group: null,
      category: null,
    });

    if (barRes?.bar?.name) {
      this.props.navigation.setOptions({
        headerTitle: barRes.bar.name
      });
    }

    if (barRes?.bar?.hidden && barRes?.bar?._id) {
      barsService.save_hidden_bar_locally(barRes.bar._id);
    }

  }

  async addConsumptionToCart(group, category, consumption) {
    let cart = this.state.cart;
    let consumptionIndex = cart.findIndex((item) => item.consumption === consumption._id);

    if (consumptionIndex === -1) {
      cart.push({
        bar: this.state?.bar?._id ? this.state?.bar?._id : this.state?.bar,
        group: group._id,
        category: category._id,
        consumption: consumption._id,
        quantity: 1
      });
    } else {
      cart[consumptionIndex].quantity++;
    }

    this.setState({ cart: cart });
    cartService.put_local_cart(cart);
  }
  
  async removeConsumptionFromCart(group, category, consumption) {
    let cart = this.state.cart;
    let consumptionIndex = cart.findIndex((item) => item.consumption === consumption._id);

    if (consumptionIndex !== -1) {
      if (cart[consumptionIndex].quantity === 1) {
        cart.splice(consumptionIndex, 1);
      } else {
        cart[consumptionIndex].quantity--;
      }
    }

    this.setState({ cart: cart });
    cartService.put_local_cart(cart);
  }

  async setConsumptionQuantityInCart(group, category, consumption, quantity) {
    let cart = this.state.cart;
    let consumptionIndex = cart.findIndex((item) => item.consumption === consumption._id);

    if (consumptionIndex === -1) {
      cart.push({
        bar: this.state?.bar?._id ? this.state?.bar?._id : this.state?.bar,
        group: group._id,
        category: category._id,
        consumption: consumption._id,
        quantity: quantity
      });
    } else {
      cart[consumptionIndex].quantity = quantity;
    }

    this.setState({ cart: cart });
    cartService.put_local_cart(cart);
  }

  getCurrentQuantity(cart, consumption) {
    let consumptionIndex = cart.findIndex((item) => item.consumption === consumption);
    if (consumptionIndex === -1) {
      return 0;
    } else {
      return cart[consumptionIndex].quantity;
    }
  }

  getTotalItems() {
    let totalItems = 0;
    this.state.cart.forEach((item) => {
      if (item.quantity > 0) {
        if (item?.type == config.consumptionInputType.INPUT.key) {
          totalItems++;
        } else {
          totalItems += item.quantity;
        }
      }
    });
    return totalItems;
  }

  getTotalCost() {
    let totalCost = 0;
    this.state.cart.forEach((item) => {
      if (item.quantity > 0) {
        let consumption = this.state.consumptions.find((consumption) => consumption._id === item.consumption);
        if (consumption) {
          totalCost += item.quantity * consumption.price;
        }
      }
    });
    return totalCost;
  }

  getCart() {
    let cart = this.state.cart;
    cart = cart.filter((item) => item.quantity > 0);
    return cart;
  }

  getCurrencyCode() {
    return this.state?.bar?.currencyCode;
  }

  async placeOrder() {
    let cart = this.state.cart;
    if (!cart.length) {
      return;
    }

    cartService.put_local_cart(this.state.cart);
    this.props.navigation.navigate('Cart', { tableNumber: this.tableNumber });
  }


  render() {

    if (!this?.state?.groups?.length) {
      return (
        <SafeAreaView style={styles.container}>
          <View style={commonStyles.centeredView}>
          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            refreshControl={
              <RefreshControl
                refreshing={this.state.loading}
                onRefresh={() => {
                  this.componentDidMount(false);
                }}
              />
            }
          >

            <View style={{ alignItems: 'center' }}>
              {(Platform.OS == "web") ? (
                <Image source={require('./../assets/icons/dishes.svg')} style={{ width: 100, height: 100 }} />
              ) : (
                <DishesIcon width={100} height={100} />
              )}
              <Text style={{fontSize: 30, fontWeight: '600', marginTop: 30, color: config.primaryColor}}>
                {I18n.t('no_consumptions_found')}
              </Text>

              {(Platform.OS == 'web') ? (
                <RefreshButton
                  onPress={() => {
                    this.componentDidMount(false);
                  }}
                  refreshing={this.state.loading}
                />
              ) : null}

            </View>
            <AlertPopUpModal
              isVisible={this.state.isModalVisible}
              icon={this.state.modal.icon}
              title={this.state.modal.title}
              text={this.state.modal.text}
              okCallback={this.state.modal.okCallback}
            ></AlertPopUpModal>
          </ScrollView>
          <View>
            <BottomNavigationBar {...this.props} activeTab={this.activeTab ? this.activeTab : "Consumptions"} />
          </View>
          </View>
        </SafeAreaView>
      );
    }

    return (
      <SafeAreaView style={styles.container}>
        <View style={{width: '100%', height: '100%', flex: 1, flexDirection: 'row'}}>
        <View style={isWideMode() ? commonStyles.leftSideView : commonStyles.centeredView}>
        <ScrollView style={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={this.state.loading}
              onRefresh={() => {
                this.componentDidMount(false);
              }}
            />
          }
        >

          {(Platform.OS == 'web') ? (
            <RefreshButton
              onPress={() => {
                this.componentDidMount(false);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <View style={{ width: '100%' }}>


              {this.state.groups.filter((group) => {
                if((group.status == config.groupStatus.INACTIVE.key) || ((!this.state.role || this.state.role == config.roles.USER.key) && group.hidden)) {
                  return false
                }
                
                // if all categories in group are inactive, dont show
                let activeCategories = group.categories.filter((category) => {
                  if((category.status == config.categoryStatus.INACTIVE.key) || ((!this.state.role || this.state.role == config.roles.USER.key) && category.hidden)) {
                    return false
                  }
                  
                  // if all consumptions in category are inactive, dont show
                  let activeConsumptions = category.consumptions.filter((consumption) => {
                    if((consumption.status == config.consumptionStatus.INACTIVE.key) || ((!this.state.role || this.state.role == config.roles.USER.key) && consumption.hidden)) {
                      return false
                    }
                    return true
                  });
                  if (!activeConsumptions.length) {
                    return false;
                  }

                  return true
                });
                if (!activeCategories.length) {
                  return false;
                }

                return true
              }).map((group, groupIndex) => {
                return (
                  <View key={groupIndex}>
                    <Text style={{fontSize: 20, fontWeight: '600', color: 'gray', marginTop: 10, marginBottom: 10, textAlign: 'center', textDecorationLine: 'underline'}}>
                      {group.name}
                    </Text>
                    {((!this.state.role || this.state.role == config.roles.USER.key) && !group.isCurrentlyAvailable) ? (
                      <>
                        <Text style={{fontSize: 15, fontWeight: '600', color: config.dangerColor, margin: 5, textAlign: 'center'}}>{I18n.t('currently_not_available')}</Text>
                        <Text style={{fontSize: 15, fontWeight: '400', color: config.dangerColor, margin: 0, textAlign: 'center'}}>{I18n.t('available_between')} {moment(group.availableFrom).format(I18n.t('OPTIONS.timeFormat'))} {I18n.t('and')} {moment(group.availableTo).format(I18n.t('OPTIONS.timeFormat'))}</Text>
                      </>
                    ) : null}
                    {(group.isCurrentlyAvailable || (this.state.role && this.state.role != config.roles.USER.key)) && (group.categories.filter((category) => {
                      if((category.status == config.categoryStatus.INACTIVE.key) || ((!this.state.role || this.state.role == config.roles.USER.key) && category.hidden)) {
                        return false
                      }
                      
                      // if all consumptions in category are inactive, dont show
                      let activeConsumptions = category.consumptions.filter((consumption) => {
                        if((consumption.status == config.consumptionStatus.INACTIVE.key) || ((!this.state.role || this.state.role == config.roles.USER.key) && consumption.hidden)) {
                          return false
                        }
                        return true
                      });
                      if (!activeConsumptions.length) {
                        return false;
                      }

                      return true
                    }).map((category, categoryIndex) => {
                      return (
                        <View key={categoryIndex}>
                          <TouchableOpacity
                            style={
                              (this.state?.group?.name == group.name && this.state?.category?.name == category.name) ?
                                (
                                  isWideMode() ?
                                    {backgroundColor: 'white', margin: 5, padding: 10, borderRadius: 7, flex:1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderWidth: 2, borderColor: config.primaryColor} :
                                    {backgroundColor: 'white', margin: 5, padding: 10, borderRadius: 7, flex:1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottomLeftRadius: 0, borderBottomRightRadius: 0}
                                ) :
                                {backgroundColor: 'white', margin: 5, padding: 10, borderRadius: 7, flex:1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}
                            }
                            onPress={() => {
                              if (this.state?.group?.name == group.name && this.state?.category?.name == category.name && !isWideMode()) {
                                this.setState({
                                  group: null,
                                  category: null
                                });
                              } else {
                                this.setState({
                                    group,
                                    category
                                });
                              }
                            }}
                          >
                            <Text style={{fontSize: 17, fontWeight: '700', color: 'black'}}>{category.name}</Text>
                            {(this.state?.group?.name == group.name && this.state?.category?.name == category.name) ? (
                              <>
                                {(Platform.OS == "web") ? (
                                  <Image source={require('./../assets/icons/minus.svg')} style={{width: 15, height: 15}} />
                                ) : (
                                  <MinusIcon />
                                )}
                              </>
                            ) : (
                              <>
                                {(Platform.OS == "web") ? (
                                  <Image source={require('./../assets/icons/right-chevron.svg')} style={{width: 15, height: 15}} />
                                ) : (
                                  <RightChevronIcon />
                                )}
                              </>
                            )}
                          </TouchableOpacity>

                          {(this.state?.group?.name == group.name && this.state?.category?.name == category.name && !isWideMode()) ? (
                            <View style={{marginTop: -5}}>
                                {category.consumptions.filter((consumption) => {
                                  if((consumption.status == config.consumptionStatus.INACTIVE.key) || ((!this.state.role || this.state.role == config.roles.USER.key) && consumption.hidden)) {
                                    return false
                                  }
                                  return true
                                }).map((consumption, consumptionIndex) => {
                                  return (
                                    <ConsumptionRowHorizontal
                                      key={consumptionIndex}
                                      grouped={true}
                                      groupedLast={consumptionIndex == category.consumptions.length - 1}
                                      disabled={Platform.OS === 'web' ? false : true}
                                      image_link={consumption?.assets?.[0] ? generateAssetLink(consumption.assets[0]) : null}
                                      title={consumption.name}
                                      secondary_title={formatPriceWithCurrencyCode(consumption.price, this.getCurrencyCode())}
                                      type={consumption?.type}
                                      round={consumption?.round}
                                      description={consumption?.description}
                                      current_quantity={this.getCurrentQuantity(this.state.cart, consumption._id)}
                                      addCallback={async () => {
                                          await this.addConsumptionToCart(group, category, consumption);
                                      }}
                                      removeCallback={async () => {
                                          await this.removeConsumptionFromCart(group, category, consumption);
                                      }}
                                      changeCallback={async (quantity) => {
                                        await this.setConsumptionQuantityInCart(group, category, consumption, quantity);
                                      }}
                                    />
                                  )
                                })}
                            </View>
                          ) : null}

                        </View>
                      );
                    }))}
                  </View>
                );
              })}
            </View>
          </View>
            <AlertPopUpModal
              isVisible={this.state.isModalVisible}
              icon={this.state.modal.icon}
              title={this.state.modal.title}
              text={this.state.modal.text}
              okCallback={this.state.modal.okCallback}
              okText={this.state.modal.okText}
              cancelCallback={this.state.modal.cancelCallback}
              cancelText={this.state.modal.cancelText}
            ></AlertPopUpModal>
        </ScrollView>
        <View>
          {this.getCart().length > 0 && (
            <>
            <View style={{justifyContent: 'space-between', flexDirection: 'row'}}>
              <View style={{justifyContent: 'center', marginLeft: 10, flex: 2}}>
                <Text style={{color: 'black', fontSize: 20, fontWeight: '500'}}>{I18n.t('total_items')} : {this.getTotalItems()}</Text>
                <Text style={{color: 'black', fontSize: 20, fontWeight: '500'}}>{I18n.t('total_cost')} : {formatPriceWithCurrencyCode(this.getTotalCost(), this.getCurrencyCode())}</Text>
              </View>
              <View style={{justifyContent: 'center', flex: 1}}>
                  
                  {(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? (
                    <TouchableOpacity
                      style={{backgroundColor: config.primaryColor, padding: 10, margin: 10, borderRadius: 10}}
                      onPress={() => {
                        this.placeOrder();
                      }}
                    >
                      <Text style={{color: 'white', fontSize: 20}}>{I18n.t('place_order')}</Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={{backgroundColor: config.primaryColor, padding: 10, margin: 10, borderRadius: 10}}
                      onPress={() => {
                        this.props.navigation.navigate('Cart', { tableNumber: this.tableNumber });
                      }}
                    >
                      <Text style={{color: 'white', fontSize: 20, textAlign: 'center'}}>{I18n.t('go_to_cart')}</Text>
                    </TouchableOpacity>
                  )}

                {/* )} */}

                
              </View>
            </View>
            {(this.state?.autoRedirected) ? (
              <View style={{textAlign: 'center'}}>
                <Text style={{color: 'black', fontSize: 12, fontWeight: '700', color: config.dangerColor, alignSelf: 'center'}}>{I18n.t('go_to_cart_and_clear_to_order_from_another_bar')}</Text>
              </View>
            ) : null}
            </>
          )}
          <BottomNavigationBar {...this.props} activeTab={this.activeTab ? this.activeTab : "Consumptions"} />
        </View>
        </View>

        {(isWideMode() && this.state.group && this.state.category) ? (
        <View style={{...commonStyles.rightSideView}}>
          <ScrollView>
            <View style={{width: '100%', height: '100%', marginTop: 5}}>

              {this.state.category.consumptions.filter((consumption) => {
                if((consumption.status == config.consumptionStatus.INACTIVE.key) || ((!this.state.role || this.state.role == config.roles.USER.key) && consumption.hidden)) {
                  return false
                }
                return true
              }).map((consumption, index) => {
                return (
                  <ConsumptionRowHorizontal
                    key={index}
                    grouped={true}
                    groupedFirst={index == 0}
                    groupedLast={index == this.state.category.consumptions.length - 1}
                    disabled={Platform.OS === 'web' ? false : true}
                    image_link={consumption?.assets?.[0] ? generateAssetLink(consumption.assets[0]) : null}
                    title={consumption.name}
                    secondary_title={formatPriceWithCurrencyCode(consumption.price, this.getCurrencyCode())}
                    type={consumption?.type}
                    round={consumption?.round}
                    description={consumption?.description}
                    current_quantity={this.getCurrentQuantity(this.state.cart, consumption._id)}
                    addCallback={async () => {
                        await this.addConsumptionToCart(this.state.group, this.state.category, consumption);
                    }}
                    removeCallback={async () => {
                        await this.removeConsumptionFromCart(this.state.group, this.state.category, consumption);
                    }}
                    changeCallback={async (quantity) => {
                      await this.setConsumptionQuantityInCart(this.state.group, this.state.category, consumption, quantity);
                    }}
                  />
                )
              })}
            </View>
          </ScrollView>
        </View>
        ) : null}

        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
});

export default Consumptions;
