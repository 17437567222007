import React, { Component } from 'react';

import { Alert, Dimensions, Image, Linking, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import QRCode from 'react-native-qrcode-svg';
// import HttpRequest from '../Http/HttpRequest';
import PopUpModal from './PopUpModal';
import LoadingScreen from './LoadingScreen';
import { getTwintDirectPaymentStatus } from '../services/twintDirect';
import i18n from '../utils/i18n';

import BankBCV from './../assets/images/bank-bcv.svg';
import BankCS from './../assets/images/bank-cs.svg';
import BankPF from './../assets/images/bank-pf.svg';
import BankRaiffeisen from './../assets/images/bank-raiffeisen.svg';
import BankUBS from './../assets/images/bank-ubs.svg';
import BankZKB from './../assets/images/bank-zkb.svg';
import BankList from './BankList';
import config from '../config';
import { openLink } from '../utils/browser';



const states = {
    DEFAULT: 1,
    LOADING: 2,
    RESULT: 3,
    NOTHING: 4,
}

let twint_direct_timer = null;

export default class TwintDirectPay extends Component {

    constructor(props) {
        super(props);

        this.state = {
            twint_direct_token: this.props.twint_direct_token,
            merchant_tran_id: this.props.merchant_tran_id,
            current_state: this.props.twint_direct_token ? states.DEFAULT : states.NOTHING,
            bank_selected: null,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.twint_direct_token !== this.props.twint_direct_token) {
            this.setState({twint_direct_token: this.props.twint_direct_token})
        }
    }

    componentWillUnmount() {
        clearInterval(twint_direct_timer);
    }


    _checkTwintDirectPaymentStatus = async (merchant_tran_id) => {
        try {

            let countTime = 0;
            twint_direct_timer = setInterval(async () => {

                let ret = await getTwintDirectPaymentStatus(merchant_tran_id);
                countTime++;

                if(ret.orderReason == "ORDER_OK" && ret.orderStatus == "SUCCESS") {
                    clearInterval(twint_direct_timer);
                    if(this.props.successCallback) {
                        this.props.successCallback(ret);
                    }
                } 
                
                if(ret.orderStatus == "IN_PROGRESS" || ret.pairingStatus == "PAIRING_ACTIVE") {
                    this.setState({current_state: states.LOADING});
                }

                if(ret.orderStatus == "FAILURE" || ret.pairingStatus == "NO_PAIRING") {
                    clearInterval(twint_direct_timer);
                    if(this.props.failureCallback) {
                        this.props.failureCallback(ret);
                    }
                } 

                if(countTime == 24) {
                    clearInterval(twint_direct_timer);
                    if(this.props.failureCallback) {
                        this.props.failureCallback(ret);
                    }
                }
            }, 5000);
        } catch (error) {
            console.log("Twint Client Accept Payment Error", error);
            clearInterval(twint_direct_timer);
            if(this.props.failureCallback) {
                this.props.failureCallback(error);
            }
        }
    }


    twintDirectTokenWebRenderer = () => {

        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isIOS = /iPad|iPhone|iPod/.test(userAgent);
        const isAndroid = /android/i.test(userAgent);

        if (isAndroid) {
            return (
                <View>
                    <TouchableOpacity
                        style={{color: 'white', backgroundColor: '#262626', marginBottom: 20, textTransform: 'none', fontSize: 20, marginTop: 20, padding: 10}}
                        onPress={() => {
                            openLink("intent://payment#Intent;action=ch.twint.action.TWINT_PAYMENT;scheme=twint;S.code=" + this.state.twint_direct_token + ";S.startingOrigin=EXTERNAL_WEB_BROWSER;S.browser_fallback_url=;end");
                        }}
                    >
                        <Text style={{padding: 10, color: 'white', fontSize: 15}} >{i18n.t('switch_to_the_app_now')}</Text>
                    </TouchableOpacity>
                </View>
            )
        } else if (isIOS) {
            return (
                <View>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <View style={{textAlign: 'center', padding: 5}}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        bank_selected: 'twint-issuer2://'
                                    })
                                }}
                                style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer2://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                            >
                                {(Platform.OS === 'web') ? (
                                    <Image source={require('./../assets/images/bank-ubs.svg')} style={{width: 60, height: 60}} />
                                ) : (
                                    <BankUBS width={60} height={60} />
                                )}
                            </TouchableOpacity>
                        </View>
                        <View style={{textAlign: 'center', padding: 5}}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        bank_selected: 'twint-issuer6://'
                                    })
                                }}
                                style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer6://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                            >
                                {(Platform.OS === 'web') ? (
                                    <Image source={require('./../assets/images/bank-raiffeisen.svg')} style={{width: 60, height: 60}} />
                                ) : (
                                    <BankRaiffeisen width={60} height={60} />
                                )}
                            </TouchableOpacity>
                        </View>
                        <View style={{textAlign: 'center', padding: 5}}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        bank_selected: 'twint-issuer7://'
                                    })
                                }}
                                style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer7://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                            >
                                {(Platform.OS === 'web') ? (
                                    <Image source={require('./../assets/images/bank-pf.svg')} style={{width: 60, height: 60}} />
                                ) : (
                                    <BankPF width={60} height={60} />
                                )}
                            </TouchableOpacity>
                        </View>
                        <View style={{textAlign: 'center', padding: 5}}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        bank_selected: 'twint-issuer3://'
                                    })
                                }}
                                style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer3://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                            >
                                {(Platform.OS === 'web') ? (
                                    <Image source={require('./../assets/images/bank-zkb.svg')} style={{width: 60, height: 60}} />
                                ) : (
                                    <BankZKB width={60} height={60} />
                                )}
                            </TouchableOpacity>
                        </View>
                        <View style={{textAlign: 'center', padding: 5}}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        bank_selected: 'twint-issuer4://'
                                    })
                                }}
                                style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer4://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                            >
                                {(Platform.OS === 'web') ? (
                                    <Image source={require('./../assets/images/bank-cs.svg')} style={{width: 60, height: 60}} />
                                ) : (
                                    <BankCS width={60} height={60} />
                                )}
                            </TouchableOpacity>
                        </View>
                        <View style={{textAlign: 'center', padding: 5}}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        bank_selected: 'twint-issuer5://'
                                    })
                                }}
                                style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer5://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                            >
                                {(Platform.OS === 'web') ? (
                                    <Image source={require('./../assets/images/bank-bcv.svg')} style={{width: 60, height: 60}} />
                                ) : (
                                    <BankBCV width={60} height={60} />
                                )}
                            </TouchableOpacity>
                        </View>
                    </View>

                    <BankList
                        selectedBank={this.state.bank_selected}
                        onBankChange={(bank) => {
                            this.setState({
                                bank_selected: bank
                            })
                        }}
                    />


                    <View item xs={12} style={{textAlign: 'center'}}>
                        <TouchableOpacity
                            disabled={!this.state.bank_selected}
                            variant="contained"
                            size="large"
                            style={this.state.bank_selected ? 
                                {color: 'white', backgroundColor: '#262626', marginBottom: 20, marginTop: 20, textTransform: 'none', fontSize: 20, padding: 10} : 
                                {color: '#ccc', backgroundColor: '#999', marginBottom: 20, marginTop: 20, textTransform: 'none', fontSize: 20, padding: 10}}
                            onPress={() => {
                                openLink(this.state.bank_selected + 'applinks/?al_applink_data={"app_action_type": "TWINT_PAYMENT","extras": {"code": "' + this.state.twint_direct_token + '"},"referer_app_link": {"target_url": "", "url": "", "app_name": "EXTERNAL_WEB_BROWSER"}, "version": "6.0" }');
                            }}
                        >
                            <Text style={{padding: 10, color: 'white', fontSize: 15, alignItems: 'center'}} >{i18n.t('switch_to_the_app_now')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            )
        } else {
            return null;
        }
    }


    twintDirectTokenAndroidRenderer = () => {
        return (
            <View>
                <TouchableOpacity
                    style={{color: 'white', backgroundColor: '#262626', marginBottom: 20, textTransform: 'none', fontSize: 20, marginTop: 20, padding: 10}}
                    onPress={() => {
                        Linking.openURL(config.webUrl + "/OpenUrl?url=" + "intent://payment#Intent;action=ch.twint.action.TWINT_PAYMENT;scheme=twint;S.code=" + this.state.twint_direct_token + ";S.startingOrigin=EXTERNAL_WEB_BROWSER;S.browser_fallback_url=;end")
                    }}
                >
                    <Text style={{padding: 10, color: 'white', fontSize: 15}} >{i18n.t('switch_to_the_app_now')}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    twintDirectTokenIosRenderer() {
        return (
            <View>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <View style={{textAlign: 'center', padding: 5}}>
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer2://'
                                })
                            }}
                            style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer2://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                        >
                            {(Platform.OS === 'web') ? (
                                <Image source={require('./../assets/images/bank-ubs.svg')} style={{width: 60, height: 60}} />
                            ) : (
                                <BankUBS width={60} height={60} />
                            )}
                        </TouchableOpacity>
                    </View>
                    <View style={{textAlign: 'center', padding: 5}}>
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer6://'
                                })
                            }}
                            style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer6://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                        >
                            {(Platform.OS === 'web') ? (
                                <Image source={require('./../assets/images/bank-raiffeisen.svg')} style={{width: 60, height: 60}} />
                            ) : (
                                <BankRaiffeisen width={60} height={60} />
                            )}
                        </TouchableOpacity>
                    </View>
                    <View style={{textAlign: 'center', padding: 5}}>
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer7://'
                                })
                            }}
                            style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer7://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                        >
                            {(Platform.OS === 'web') ? (
                                <Image source={require('./../assets/images/bank-pf.svg')} style={{width: 60, height: 60}} />
                            ) : (
                                <BankPF width={60} height={60} />
                            )}
                        </TouchableOpacity>
                    </View>
                    <View style={{textAlign: 'center', padding: 5}}>
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer3://'
                                })
                            }}
                            style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer3://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                        >
                            {(Platform.OS === 'web') ? (
                                <Image source={require('./../assets/images/bank-zkb.svg')} style={{width: 60, height: 60}} />
                            ) : (
                                <BankZKB width={60} height={60} />
                            )}
                        </TouchableOpacity>
                    </View>
                    <View style={{textAlign: 'center', padding: 5}}>
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer4://'
                                })
                            }}
                            style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer4://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                        >
                            {(Platform.OS === 'web') ? (
                                <Image source={require('./../assets/images/bank-cs.svg')} style={{width: 60, height: 60}} />
                            ) : (
                                <BankCS width={60} height={60} />
                            )}
                        </TouchableOpacity>
                    </View>
                    <View style={{textAlign: 'center', padding: 5}}>
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({
                                    bank_selected: 'twint-issuer5://'
                                })
                            }}
                            style={{borderRadius: 10, borderWidth: 1, borderColor: this.state.bank_selected == 'twint-issuer5://' ? config.primaryColor : config.backgroundColor, padding: 5}}
                        >
                            {(Platform.OS === 'web') ? (
                                <Image source={require('./../assets/images/bank-bcv.svg')} style={{width: 60, height: 60}} />
                            ) : (
                                <BankBCV width={60} height={60} />
                            )}
                        </TouchableOpacity>
                    </View>
                </View>

                <BankList
                    selectedBank={this.state.bank_selected}
                    onBankChange={(bank) => {
                        this.setState({
                            bank_selected: bank
                        })
                    }}
                />


                <View item xs={12} style={{textAlign: 'center'}}>
                    <TouchableOpacity
                        disabled={!this.state.bank_selected}
                        variant="contained"
                        size="large"
                        style={this.state.bank_selected ? 
                            {color: 'white', backgroundColor: '#262626', marginBottom: 20, marginTop: 20, textTransform: 'none', fontSize: 20, padding: 10} : 
                            {color: '#ccc', backgroundColor: '#999', marginBottom: 20, marginTop: 20, textTransform: 'none', fontSize: 20, padding: 10}}
                        onPress={() => {
                            Linking.openURL(config.webUrl + "/OpenUrl?url=" + this.state.bank_selected + 'applinks/?al_applink_data={"app_action_type": "TWINT_PAYMENT","extras": {"code": "' + this.state.twint_direct_token + '"},"referer_app_link": {"target_url": "", "url": "", "app_name": "EXTERNAL_WEB_BROWSER"}, "version": "6.0" }')
                        }}
                    >
                        <Text style={{padding: 10, color: 'white', fontSize: 15, alignItems: 'center'}} >{i18n.t('switch_to_the_app_now')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    getRender = () => {

        const win = Dimensions.get('window');
        const ratio = win.width/850; // 2842 is actual image width
        const width = win.width;
        const height = 350 * ratio; // 297 is actual image height

        const imageStyle = {
            width: width * 0.6,
            height: height * 0.6,
            marginTop: 0,
            marginBottom: 20,
        }

        switch(this.state.current_state) {

            case states.NOTHING:
                return (
                    <></>
                )

            case states.DEFAULT:

                // console.log("UID", this.props.uid);

                if (this.props.merchant_tran_id) {
                    clearInterval(twint_direct_timer);
                    this._checkTwintDirectPaymentStatus(this.props.merchant_tran_id);
                }

                return (
                    <ScrollView
                        contentContainerStyle={{justifyContent: 'center', alignItems: 'center', padding: 20}}
                    >

                        <Image source={require('../assets/images/twint_logo.png')} style={imageStyle} />

                        <Text style={{color: 'gray', fontWeight: '700', fontSize: 25, marginBottom: 20, marginTop: 20, textAlign: 'center'}}>{i18n.t('please_scan_the_following_twint_qr_code')}</Text>

                        <QRCode
                            value={this.state.twint_direct_token}
                            backgroundColor="transparent"
                            size={350}
                        />

                        {/* Display token as separate digits in a box */}
                        <View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 20}}>
                            {this.props?.twint_direct_token?.split('').map((digit, index) => {
                                return (
                                    <View key={index} style={{borderWidth: 1, borderColor: 'gray', padding: 10, margin: 5, borderRadius: 5}}>
                                        <Text style={{fontSize: 30, fontWeight: '700', color: 'gray'}}>{digit}</Text>
                                    </View>
                                )
                            })}
                        </View>

                        {(Platform.OS == 'web') ? this.twintDirectTokenWebRenderer() : null}

                        {(Platform.OS == 'android') ? this.twintDirectTokenAndroidRenderer() : null}

                        {(Platform.OS == 'ios') ? this.twintDirectTokenIosRenderer() : null}

                    </ScrollView>
                )

            case states.LOADING:
                return (
                    <PopUpModal isVisible = {true}>
                        <LoadingScreen
                            callback={() => {
                                // EntranceGroupsMananger.removeEntranceGroups();
                                // this.setState({entrance_groups: []});
                                // this.componentDidMount();
                                clearInterval(twint_direct_timer);
                                if(this.props.cancelCallback) {
                                    this.props.cancelCallback();
                                }
                            }}
                        />
                    </PopUpModal>                    
                )

        }
    }


    render(){
        return (
            this.getRender()
        );
    }
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
})